import axios from "axios";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Home = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);

  const phonePay = async (data) => {
    const response = await axios.post(
      "https://newapi.creditbee.cfd/phonepay",
      data
    );

    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response.data];
  };

  const payHandler = async () => {
    setLoading(true);
    const [success, response] = await phonePay({
      amount: +amount * 100,

      deviceContext: {
        deviceOS: "ANDROID",
      },
      merchantId: "M22I8787FQRGZ",

      redirectUrl: `https://creditbee.cfd/sign-up/payment-method/pay/success`,
      redirectMode: "GET",
      merchantTransactionId: generateRandomString(10).toUpperCase(),
      merchantUserId: generateRandomString(10).toUpperCase(),
      mobileNumber: generateRandomString(10).toUpperCase(),
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    });

    console.log(success);

    if (success) {
      window.location.replace(
        response?.data?.data?.instrumentResponse?.redirectInfo?.url
      );
    }
    setLoading(false);
  };
  const amount = searchParams.get("amount");

  useEffect(() => {
    payHandler();
  }, [amount]);
  return <div>{amount}</div>;
};

export default Home;
